(function () {
  'use strict';

  class EditGroups {
    constructor($mdDialog, $document) {
      let vm = this;
      vm.$mdDialog = $mdDialog;
      vm.body = $document[0].body;
    }

    show(ev, group, classroomsIndex, callback) {
      const vm = this;
      vm.obj = {group: group, classroomsIndex: classroomsIndex};
      vm.$mdDialog.show({
        controller: 'EditGroupsCtrl',
        controllerAs: 'editGroups',
        templateUrl: 'components/edit-groups/edit-groups.tpl.html',
        parent: angular.element(vm.body),
        targetEvent: ev,
        clickOutsideToClose: true
      })
        .then(callback);
    }
    getObj() {
      const vm = this;
      return vm.obj;
    }
  }

  /**
   * @ngdoc service
   * @name components.service:EditGroups
   *
   * @description
   *
   */
  angular
    .module('components')
    .service('EditGroups', EditGroups);
}());
